import React from 'react';
import { Element } from 'react-scroll';

const items = [
    {
        title: 'Clases Flexibles',
        info: 'Podrás visualizarlas cuando quieras y tantas veces como sea necesario.',
        icon: 'icon-Hand---Book'
    },
    {
        title: 'Aprende desde cualquier lugar',
        info: 'Conectaté y sigue avanzando en tus objetivos desde nuestra plataforma, podrás visualizar temas, clases y practicar técnicas test con nuestras preguntas novedosas.',
        icon: 'icon-Campus'
    }
];

const About = () => {
    return (
        <Element
            name="about-us"
            className="edu-about-area about-style-1 edu-section-gap bg-color-white"
        >
            <div className="container">
                <div className="row g-5">
                    <div className="col-lg-6">
                        <div className="about-image-gallery">
                            <img className="image-1" src="/images/eyra/nurse-02.jpg" alt="About Main Thumb" />
                            <div className="image-2">
                                <img src="/images/eyra/nurse-01.jpg" alt="About Parallax Thumb" />
                            </div>
                            <div className="shape-image shape-image-1">
                                <img src="/images/shapes/shape-04-01.png" alt="Shape Thumb" />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="inner">
                            <div className="section-title">
                                <span className="pre-title">Sobre Nosotros</span>
                                <h3 className="title">Trabajando juntos para conseguir tu plaza</h3>
                            </div>
                            <br/>
                            { items && items.length > 0 &&
                                <div className="about-feature-list">
                                    { items.map( ( data , i ) => (
                                        <div className="our-feature" key={i}>
                                            <div className="our-feature">
                                                <div className="icon">
                                                    <i className={ data.icon }></i>
                                                </div>
                                                <div className="feature-content">
                                                    <h6 className="feature-title">{ data.title }</h6>
                                                    <p className="feature-description">{ data.info }</p>
                                                </div>
                                            </div>
                                        </div>
                                    ) ) }
                                </div>
                            }
                            <div className="shape shape-6 about-parallax-2 d-xl-block d-none">
                                <img src="/images/shapes/shape-07.png" alt="Shape Thumb" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Element>
    )
}

export default About;