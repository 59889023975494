import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFunctions } from 'firebase/functions';

import LandingEyra from './pages/LandingEyra';
import CourseOne from './pages/course/CourseOne';
import CourseDetails from './pages/detailspages/CourseDetails';
import Error from './pages/innerpages/Error';

import ScrollToTop from './components/scrolltotop/ScrollToTop';

import './assets/scss/style.scss';

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
  };

const app = initializeApp(firebaseConfig);
export const functions = getFunctions(app);


if (process.env.NODE_ENV === 'production') {
    getAnalytics(app);
} else {
    console.log('Analytics are disabled');
}

function App() {
    return (
        <Router>
            <ScrollToTop>
                <Routes>
                    <Route exact path='/' element={<LandingEyra/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/cursos'}`} element={<CourseOne/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/curso/:id'}`} element={<CourseDetails/>}/>
                    <Route path='*' element={<Error/>}/>
                </Routes>
            </ScrollToTop>
        </Router>
    );
}

export default App;