import React , { useState } from 'react';

import { sendRegistration } from '../../services/RegistrationEmail';

const Result = () => {
    return (
        <p className="success-message">¡Formulario completado! Gracias por la confianza depositada en Eyra Formación.</p>
    )
}

function RegistrationCourseForm({ formStyle, data }) {
    const [result, setResult] = useState( false );

    const sendEmail = ( e ) => {
        e.preventDefault();
        sendRegistration(
            e.target.firstname.value,
            e.target.lastname.value,
            e.target.dni.value,
            e.target.email.value,
            e.target.address.value,
            e.target.phone.value,
            data.registration_fee,
            data.registration_fee_old,
            data.price,
            data.price_old,
            data.thumbnail_title
        )
        .then(
            (result) => {
                console.log( result.text );
            },
            (error) => {
                console.log( error.text );
            }
        );
        e.target.reset();
        setResult( true );
    };

    return (
        <>
            {
                result ? (
                    <div className="form-group"><Result /></div>
                )  : (
                    <form className={`${ formStyle }`} action="" onSubmit={ sendEmail }>
                        <div className="col-lg-12">
                            <div className="form-group">
                                <input name="firstname" type="text" className="form-control form-control-lg" placeholder="Nombre *" required />
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="form-group">
                                <input name="lastname" type="text" className="form-control form-control-lg" placeholder="Apellidos *" required />
                            </div>
                        </div>

                        <div className="col-lg-12">
                            <div className="form-group">
                                <input name="dni" type="text" className="form-control form-control-lg" placeholder="DNI *" required />
                            </div>
                        </div>

                        <div className="col-lg-12">
                            <div className="form-group">
                                <input name="email" type="email" className="form-control form-control-lg" placeholder="Email *" required />
                            </div>
                        </div>

                        <div className="col-lg-12">
                            <div className="form-group">
                                <input name="address" type="text" className="form-control form-control-lg" placeholder="Dirección Completa *" required />
                            </div>
                        </div>

                        <div className="col-lg-12">
                            <div className="form-group">
                                <input name="phone" type="phone" className="form-control form-control-lg" placeholder="Teléfono *" required/>
                            </div>
                        </div>

                        <div className="col-lg-12">
                            <button className="rn-btn edu-btn w-100" type="submit">
                                <span>Enviar</span><i className="icon-arrow-right-line-right"></i></button>
                        </div>
                    </form>
                )
            }
        </>

    )
}
export default RegistrationCourseForm;