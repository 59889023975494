import React, { useState } from 'react';
// import FsLightbox from 'fslightbox-react';
import RegistrationModal from '../registration/RegistrationModal';
const CourseInfo = ({ data }) => {
    // const [toggler, setToggler] = useState( false );
    return (
        <>
            <div className="eduvibe-sidebar course-details-sidebar">
                <div className="inner">
                    <div className="eduvibe-widget">
                        {/* <div className="video-area">
                            <div className="thumbnail video-popup-wrapper">
                                <img className="radius-small w-100" src={`${process.env.PUBLIC_URL}/images/course/video-bg/${data.image}`} alt="Course Video Thumb" />
                                <button onClick={ () => setToggler( ! toggler ) } className="video-play-btn position-to-top video-popup-activation">
                                    <span className="play-icon"></span>
                                </button>
                                <FsLightbox toggler={ toggler } sources={ data.videoLink } />
                            </div>
                        </div> */}
                        <div className="eduvibe-widget-details mt--35">
                            <div className="widget-content">
                                <ul>
                                    { data.duration && <li><span><i className="icon-time-line"></i> Duración</span><span>{data.duration}</span></li> }
                                    { data.student && <li><span><i className="icon-user-2"></i> Matriculados</span><span>{data.student}</span></li> }
                                    { data.lesson && <li><span><i className="icon-draft-line"></i> Temas</span><span>{data.lesson}</span></li> }
                                    { data.quizzes && <li><span><i className="icon-artboard-line"></i> Simulacros</span><span>{data.quizzes}</span></li> }
                                    { data.certificate && <li><span><i className="icon-award-line"></i> Certificado</span><span>{data.certificate === 'available' ? 'Yes' : 'No'}</span></li> }
                                    { data.passPercentage && <li><span><img className="eduvibe-course-sidebar-img-icon" src="/images/icons/percent.svg" alt="icon Thumb" />Porcentaje de aprobados</span><span>{data.passPercentage}%</span></li> }
                                    { data.level && <li><span><i className="icon-bar-chart-2-line"></i> Nivel</span><span>{data.level}</span></li> }
                                    { data.deadline && <li><span><i className="icon-calendar-2-line"></i> Plazo Matrícula</span><span>{data.deadline}</span></li> }
                                </ul>
                                <RegistrationModal data={data}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CourseInfo;