import React , { useState } from 'react';

import { sendContact } from '../../services/ContactEmail';

const Result = () => {
    return (
        <p className="success-message">Gracias por su consulta. Nos pondremos en contacto con usted en breve.</p>
    )
}

function ContactCourseForm( { formStyle, course } ) {
    const [result, setResult] = useState( false );

    const sendEmail = ( e ) => {
        e.preventDefault();

        sendContact(
            e.target.fullname.value,
            e.target.email.value,
            e.target.phone.value,
            course,
            e.target.comentarios.value
        )
        .then(
            (result) => {
                console.log(result.text);
            },
            (error) => {
                console.log(error.text);
            }
        );
        e.target.reset();
        setResult(true);
    };

    setTimeout(() => {
        setResult(false);
    }, 5000);

    return (
        <form className={`${ formStyle }`} action="" onSubmit={ sendEmail }>
            <div className="col-lg-12">
                <div className="form-group">
                    <input name="fullname" type="text" className="form-control form-control-lg" placeholder="Nombre*" required />
                </div>
            </div>

            <div className="col-lg-12">
                <div className="form-group">
                    <input name="email" type="email" className="form-control form-control-lg" placeholder="Email*" required />
                </div>
            </div>

            <div className="col-lg-12">
                <div className="form-group">
                    <input name="phone" type="phone" className="form-control form-control-lg" placeholder="Teléfono*" required/>
                </div>
            </div>

            <div className="col-lg-12">
                <div className="form-group">
                    <textarea name="comentarios" className="form-control" placeholder="Comentarios"></textarea>
                </div>
            </div>
            <div className="col-lg-12">
                <button className="rn-btn edu-btn w-100" type="submit">
                    <span>Enviar</span><i className="icon-arrow-right-line-right"></i></button>
            </div>
            { result ? <div className="form-group"><Result /></div>  : null }
        </form>
    )
}
export default ContactCourseForm;