import InstructorData from '../data/instructor/InstructorData.json';


export function getInstructorsFromIdsList(ids) {
    return InstructorData.reduce((result, instructor) => {
        if (ids.includes(instructor.id)) {
            result.push(instructor);
        }
        return result;
    }, []);

}