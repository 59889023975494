import emailjs from 'emailjs-com';
import { getFunctions, httpsCallable } from 'firebase/functions';

export function sendRegistration(firstname, lastname, dni, email, address, phone, registration_fee, registration_fee_old, price, price_old, course) {
    const templateParams = {
        firstname: firstname,
        lastname: lastname,
        dni: dni,
        email: email,
        address: address,
        phone: phone,
        registration_fee: registration_fee,
        registration_fee_old: registration_fee_old,
        price: price,
        price_old: price_old,
        course: course
    };

    const functions = getFunctions();
    const registration = httpsCallable(functions, 'registration');

    registration({data: templateParams}).then((result) => {
        console.log("Registration: " + result.result)
    }).catch((error) => {
        console.log("Registration: " + error)
    });

    return emailjs.send(
        process.env.REACT_APP_EMAILJS_REGISTRATION_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_REGISTRATION_TEMPLATE_ID,
        templateParams,
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY
    );
}