import React from 'react';
import SEO from '../common/SEO';
import HeaderLandingPage from '../common/header/HeaderLandingPage';
import BannerLanding from '../components/banner/BannerLanding';
import FooterOne from '../common/footer/FooterOne';
import About from '../components/about/About';

const LandingEyra = () => {
    return (
        <>
            <SEO title="Inicio" />

            <HeaderLandingPage styles="header-transparent" />

            <BannerLanding />

            <About />

            <FooterOne />
        </>
    )
}

export default LandingEyra;