import PropTypes from 'prop-types';
import React from 'react'


const SEO = ( { title } ) => {
    return (
        <>
            <meta charSet="utf-8" />
            <title>EYRA Formación Sanitaria - {title}</title>
            <meta name="robots" content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1" />
            <meta name="description" content="Academia especializada en formación para sanitarios. Prepárate con nosotros para alcanzar en las siguientes oposiciones tu plaza de enfermera o conseguir tu especialidad EIR" />
            <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0" />
        </>
    )
}
SEO.propTypes = {
    title: PropTypes.string
};

export default SEO;