import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';

import RegistrationCourseForm from './RegistrationCourseForm';

const RegistrationModal = ( { data }) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <div className="read-more-btn mt--45" onClick={handleShow}>
                <a href="#" className="edu-btn w-100 text-center">Inscríbete</a>
            </div>

            <Modal show={show} className="edu-product-modal" onHide={handleClose}>
                <Modal.Body className="edu-modal-body">
                    <div className="container eduvibe-animated-shape">
                        <div className="row g-5">
                            <div className="col-lg-12">
                                <div className="testimonial-four-right-content">
                                    <div className="section-title text-start mb--45">
                                        <h3 className="title">Proceso de Inscripción</h3>
                                    </div>
                                    <h6 className="subtitle">Instrucciones</h6>
                                    <div className="col-lg-12">
                                        <div className="feature-style-3">
                                            <span className="feature-content">
                                                <span className="feature-title">1.  </span>
                                            </span>
                                            <span className="section-title text-start mb--45">
                                                <span className="pre-title">Formulario de Registro</span>
                                            </span>
                                            <RegistrationCourseForm
                                                formStyle="edu-modal-form rnt-contact-form rwt-dynamic-form row"
                                                data={data}/>
                                        </div>
                                    </div>
                                    <br/>
                                    <div className="col-lg-12">
                                        <div className="feature-style-3">
                                            <span className="feature-content">
                                                <span className="feature-title">2.  </span>
                                            </span>
                                            <span className="section-title text-start mb--45">
                                                <span className="pre-title">Recibirás un email con la información para realizar el pago de la matrícula.</span>
                                            </span>
                                        </div>
                                    </div>
                                    <br/>
                                    <div className="col-lg-12">
                                        <div className="feature-style-3">
                                            <span className="feature-content">
                                                <span className="feature-title">3.  </span>
                                            </span>
                                            <span className="section-title text-start mb--45">
                                                <span className="pre-title">Nos pondremos en contacto contigo para el comienzo del curso.</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br/>
                        <div className="row g-5">
                            <div className="col-lg-12">
                                <p className="edu-">EYRA formación en adelante RESPONSABLE, es el responsable del tratamiento de los datos personales del usuario y le informa que estos datos serán tratados de conformidad con lo dispuesto en el Reglamento (UE) 2016/679 del 27 de abril (GDPR) y la Ley Orgánica 3/2018 de 5 de diciembre (LOPDGDD)</p>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default RegistrationModal;