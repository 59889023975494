import emailjs from 'emailjs-com';

export function sendContact(fullname, email, phone, course, comments) {
    const templateParams = {
        fullname: fullname,
        email: email,
        phone: phone,
        comments: comments,
        course: course
    };

    return emailjs.send(
        process.env.REACT_APP_EMAILJS_CONTACT_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_CONTACT_TEMPLATE_ID,
        templateParams,
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY
    );
}