import React from 'react';
import { Link } from 'react-router-dom';

const Nav = () => {
    return (
        <ul className="mainmenu">
            <li><Link to="/">Inicio</Link></li>
            <li><Link to="/cursos">Cursos</Link></li>
        </ul>
    )
}
export default Nav;
