import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import SEO from '../../common/SEO';
import Layout from '../../common/Layout';

import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import CourseInfo from '../../components/course/CourseInfo';
import ContactCourseForm from '../../components/contact/ContactCourseForm';

import CourseData from '../../data/course/CourseData.json';
import { getInstructorsFromIdsList } from '../../services/Data';


const InstructorContent = ({instructors}) => {
    return instructors.map((instructor) =>
        <div className="course-author-wrapper" key={instructor.id}>
            <div className="thumbnail">
                <img src={`${process.env.PUBLIC_URL}/images/instructor/course-details/${instructor.image}`} alt="Author Thumb" />
            </div>
            <div className="author-content">
                <h6 className="title">{instructor.name}</h6>
                <span className="subtitle">{instructor.designation}</span>
                <p>{ instructor.details }</p>
            </div>
        </div>
    );
}

const CourseDetails = () => {
    const { id } = useParams();
    const data = CourseData.filter( course => course.id === id );
    const courseItem = data[0];

    const [contentTab, setContentTab] = useState( 'overview' );
    const handleTab = ( content ) => {
        if ( content === 'overview' ) {
            setContentTab( 'overview' );
        } else if ( content === 'pricing' ) {
            setContentTab( 'pricing' );
        } else if ( content === 'curriculum' ) {
            setContentTab( 'curriculum' );
        } else if ( content === 'instructor' ) {
            setContentTab( 'instructor' );
        } else if ( content === 'reviews' ) {
            setContentTab( 'reviews' );
        }
    }

    const instructors = getInstructorsFromIdsList(courseItem.instructors);

    return (
        <>
            <SEO title={ courseItem.title } />
            <Layout>
                <BreadcrumbOne
                    title={ courseItem.breadcrumb_title }
                    rootUrl="/"
                    parentUrl="Inicio"
                    currentUrl={ courseItem.breadcrumb_title }
                />
                <div className="edu-course-details-area edu-section-gap bg-color-white">
                    <div className="container">
                        <div className="row g-5">
                            <div className="col-lg-12">
                                <div className="main-image thumbnail">
                                    <img className="radius-small" src={`${process.env.PUBLIC_URL}/images/course/course-details/${courseItem.image}`} alt={courseItem.image_alt} />
                                    <div className="edu-unspash" dangerouslySetInnerHTML={{__html: courseItem.image_author}} />
                                </div>
                            </div>
                        </div>

                        <div className="row g-5">
                            <div className="col-xl-8 col-lg-7">
                                <div className="course-details-content">
                                    <br/>
                                    <h3 className="title">{ courseItem.title }</h3>
                                    <ul className="edu-course-tab nav nav-tabs" role="tablist">
                                        <li className="nav-item">
                                            <button
                                                className={contentTab === 'overview' ? 'nav-link active' : 'nav-link'}
                                                type="button"
                                                aria-label="Overview"
                                                onClick={() => handleTab('overview')}
                                            >
                                                Descripción
                                            </button>
                                        </li>
                                        <li className="nav-item">
                                            <button
                                                className={contentTab === 'pricing' ? 'nav-link active' : 'nav-link'}
                                                type="button"
                                                aria-label="pricing"
                                                onClick={() => handleTab('pricing')}
                                            >
                                                Precio
                                            </button>
                                        </li>
                                    </ul>

                                    <div className="tab-content">
                                        { contentTab === 'overview' &&
                                            <div className={`tab-pane fade show ${contentTab === 'overview' ? 'active' : '' } `}>
                                                <div className="course-tab-content" dangerouslySetInnerHTML={{__html: courseItem.details}} />
                                            </div>
                                        }

                                        { contentTab === 'pricing' &&
                                            <div className={`tab-pane fade show ${contentTab === 'pricing' ? 'active' : '' } `}>
                                                <div className="course-tab-content" dangerouslySetInnerHTML={{__html: courseItem.pricing}} />
                                            </div>
                                        }

                                        { contentTab === 'reviews' &&
                                            <div className={`tab-pane fade show ${contentTab === 'reviews' ? 'active' : '' } `}>
                                                <div className="course-tab-content">
                                                    <div className="row row--30">
                                                        <div className="col-lg-4">
                                                            <div className="rating-box">
                                                                <div className="rating-number">{courseItem.rating}</div>
                                                                <div className="rating eduvibe-course-rating-stars">
                                                                    <i className="icon-Star"></i>
                                                                    <i className="icon-Star"></i>
                                                                    <i className="icon-Star"></i>
                                                                    <i className="icon-Star"></i>
                                                                    <i className="icon-Star"></i>
                                                                </div>
                                                                <span>({courseItem.review} Review)</span>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-8">
                                                            <div className="review-wrapper">

                                                                <div className="single-progress-bar">
                                                                    <div className="rating-text">
                                                                        5 <i className="icon-Star"></i>
                                                                    </div>
                                                                    <div className="progress">
                                                                        <div className="progress-bar" role="progressbar" style={ {width: '100%'} } aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                                                    </div>
                                                                    <span className="rating-value">1</span>
                                                                </div>

                                                                <div className="single-progress-bar">
                                                                    <div className="rating-text">
                                                                        4 <i className="icon-Star"></i>
                                                                    </div>
                                                                    <div className="progress">
                                                                        <div className="progress-bar" role="progressbar" style={ {width: '0%'} } aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                                                                    </div>
                                                                    <span className="rating-value">0</span>
                                                                </div>

                                                                <div className="single-progress-bar">
                                                                    <div className="rating-text">
                                                                        3 <i className="icon-Star"></i>
                                                                    </div>
                                                                    <div className="progress">
                                                                        <div className="progress-bar" role="progressbar" style={ {width: '0%'} } aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                                                                    </div>
                                                                    <span className="rating-value">0</span>
                                                                </div>

                                                                <div className="single-progress-bar">
                                                                    <div className="rating-text">
                                                                        2 <i className="icon-Star"></i>
                                                                    </div>
                                                                    <div className="progress">
                                                                        <div className="progress-bar" role="progressbar" style={ {width: '0%'} } aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                                                                    </div>
                                                                    <span className="rating-value">0</span>
                                                                </div>

                                                                <div className="single-progress-bar">
                                                                    <div className="rating-text">
                                                                        1 <i className="icon-Star"></i>
                                                                    </div>
                                                                    <div className="progress">
                                                                        <div className="progress-bar" role="progressbar" style={ {width: '0%'} } aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                                                                    </div>
                                                                    <span className="rating-value">0</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="comment-wrapper pt--40">
                                                        <div className="section-title">
                                                            <h5 className="mb--25">Reviews</h5>
                                                        </div>
                                                        <div className="edu-comment">
                                                            <div className="thumbnail">
                                                                <img src="/images/course/student-review/student-1.png" alt="Student Thumb" />
                                                            </div>
                                                            <div className="comment-content">
                                                                <div className="comment-top">
                                                                    <h6 className="title">Elen Saspita</h6>
                                                                    <div className="rating eduvibe-course-rating-stars">
                                                                        <i className="icon-Star"></i>
                                                                        <i className="icon-Star"></i>
                                                                        <i className="icon-Star"></i>
                                                                        <i className="icon-Star"></i>
                                                                        <i className="icon-Star"></i>
                                                                    </div>
                                                                </div>
                                                                <span className="subtitle">“ Outstanding Course ”</span>
                                                                <p>As Thomas pointed out, Chegg’s survey appears more like a scorecard that details obstacles and challenges that the current university undergraduate student population is going through in their universities and countries.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-5">
                                <CourseInfo data={courseItem}/>
                            </div>
                        </div>
                        <br/>
                        <div className="container eduvibe-animated-shape">
                            <div className="row g-5">
                                <div className="col-lg-12">
                                    <div className="section-title text-center">
                                        <h3 className="title">Contacta con nosotros</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="row g-5 mt--20">
                                <div className="col-lg-6">
                                    <div className="contact-info pr--70 pr_lg--0 pr_md--0 pr_sm--0">
                                        <div className="row g-5">
                                            <div className="col-lg-5 col-md-5 col-sm-5 col-12">
                                                <div className="contact-address-card-1 phone">
                                                    <div className="inner">
                                                        <div className="icon">
                                                            <i className="icon-Headphone"></i>
                                                        </div>
                                                        <div className="content">
                                                            <h6 className="title">Llámanos</h6>
                                                            <p><a href="tel: +34 695 69 35 39">+34 695 69 35 39</a></p>
                                                            <p><a href="tel: +34 685 09 43 73">+34 685 09 43 73</a></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-7 col-md-7 col-sm-7 col-12">
                                                <div className="contact-address-card-1 email">
                                                    <div className="inner">
                                                        <div className="icon">
                                                            <i className="icon-mail-open-line"></i>
                                                        </div>
                                                        <div className="content">
                                                            <h6 className="title">Envianos un email</h6>
                                                            <p><a href="mailto:secretaria@eyraformacion.es">secretaria@eyraformacion.es</a></p>
                                                            <br/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <ContactCourseForm formStyle="rnt-contact-form rwt-dynamic-form row" course={courseItem.thumbnail_title}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}
export default CourseDetails;