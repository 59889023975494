import React from 'react';
import { Link } from 'react-router-dom';
import ScrollTopButton from './ScrollTopButton';

const FooterOne = () => {
    return (
        <>
            <footer className="eduvibe-footer-one edu-footer footer-style-default">
                <div className="footer-top">
                    <div className="container eduvibe-animated-shape">
                        <div className="row g-5">
                            <div className="col-lg-2 col-md-6 col-sm-12 col-12">
                                <div className="edu-footer-widget">
                                    <div className="logo">
                                        <Link to={process.env.PUBLIC_URL + "/"}>
                                            <img className="logo-light" src="/images/logo/logo-white.png" alt="Footer Logo" />
                                        </Link>
                                    </div>
                                    {/* <ul className="social-share">
                                        <li><a href="#"><i className="icon-Fb"></i></a></li>
                                        <li><a href="#"><i className="icon-linkedin"></i></a></li>
                                        <li><a href="#"><i className="icon-Pinterest"></i></a></li>
                                        <li><a href="#"><i className="icon-Twitter"></i></a></li>
                                    </ul> */}
                                </div>
                            </div>

                            <div className="col-lg-8 col-md-6 col-sm-6 col-12">
                                <div className="edu-footer-widget explore-widget">
                                    <h5 className="widget-title">Política de Calidad</h5>
                                    <div className="inner">
                                        <p className="description">EYRA FORMACION, en su actividad de SERVICIOS DE FORMACION ONLINE, tenemos decidido el compromiso de orientar todos nuestros esfuerzos a satisfacer las necesidades de nuestras partes interesadas, siendo la Calidad de nuestros servicios la máxima prioridad, con el fin de obtener una mejora continua en nuestra organización. Para ello, hemos implantado y mantenemos un sistema de Gestión de la Calidad basado en los requisitos de la norma ISO 9001 y que adopta los siguientes compromisos de calidad:</p>
                                        <ul>
                                            <li className="description">Mantener eficazmente un Sistema de Gestión de la Calidad y promover su mejora continua.</li>
                                            <li className="description">Cumplir con todos los requisitos de nuestras partes interesadas y aquellos que nos sean aplicables para consolidar su confianza en nuestra organización.</li>
                                            <li className="description">Garantizar el correcto estado de las instalaciones y el equipamiento adecuado de forma tal que estén en correspondencia con la actividad, objetivos y metas de la empresa.</li>
                                            <li className="description">Compromiso con el cliente para que disponga de las soluciones necesarias y adecuadas a sus circunstancias, manteniendo el vínculo en el tiempo y fomentando la confianza en la Calidad y Excelencia de nuestro trabajo.</li>
                                        </ul>
                                        <p className="description">Estos principios son asumidos por La Dirección quien dispone los medios necesarios y dota a sus empleados de los recursos suficientes para su cumplimiento, plasmándolos y poniéndolos en público conocimiento a través de la presente Política de Calidad.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-6 col-sm-6 col-12">
                                <div className="edu-footer-widget">
                                    <h5 className="widget-title">Contacto</h5>
                                    <div className="inner">
                                        <div className="widget-information">
                                            <ul className="information-list">
                                                <li><i className="icon-phone-fill"></i><a href="tel: +34 695 69 35 39">+34 695 69 35 39</a></li>
                                                <li><i className="icon-phone-fill"></i><a href="tel: +34 685 09 43 73">+34 685 09 43 73</a></li>
                                                <li><i className="icon-mail-line-2"></i><a target="_blank" rel="noreferrer" href="mailto:secretaria@eyraformacion.es">secretaria@eyraformacion.es</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="shape-dot-wrapper shape-wrapper d-md-block d-none">
                            <div className="shape-image shape-image-1">
                                <img src="/images/shapes/shape-21-01.png" alt="Shape Thumb" />
                            </div>
                            <div className="shape-image shape-image-2">
                                <img src="/images/shapes/shape-35.png" alt="Shape Thumb" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="copyright-area copyright-default">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner text-center">
                                    <p>Copyright 2022 EYRA Formación. Todos los derechos reservados</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            <ScrollTopButton />
        </>
    )
}

export default FooterOne;